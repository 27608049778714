/**
 * Autoloader
 * @param into
 */
window.insertAutoLoader = function (into) {
    const loaderWidth = $(into).width();
    const loaderHeight = $(into).height();
    $(into).prepend('<div class="loader" style="position:absolute; width:' + loaderWidth + 'px; height:' + loaderHeight + 'px;"></div>');
};

/**
 * Ajax Lightbox
 * @param file
 * @param params
 *
 * Beispiel:
 *
 <div class="overlay-header">Header</div>
 <div class="overlay-body">Body</div>
 <div class="overlay-body">Body</div>
 <div class="overlay-footer">Footer</div>
 *
 * Aufruf:
 * <a href="#" onclick="ajaxDialog('url.php'); return false;">Link</a>
 **/
window.ajaxDialog = function (file, params) {
    const self = this;

    self.showDialog = function () {
        var overlay = $('.overlay');

        // Wenn Dialog schon vorhanden, dann nicht mehr schließen
        if (overlay.length === 0) {
            var loaderContent = '<div class="section-content"><div class="section-head"><div class="loader"><span></span><span></span><span></span></div> <h2>Wird geladen...</h2></div></div><div></div>';
            $(config.container).append('<div class="overlay"><div class="overlay-container">' + loaderContent + '</div></div>');

            overlay.show();
            $('.overlay-close').on('click', function () {
                self.closeDialog();
            });
        }

        $('embed, object').css('visibility', 'hidden');
        $('body').css('overflow', 'hidden');

        if (typeof file === 'string') {
            self.loadDialog();
        }
        if (typeof file === 'object') {
            $('.overlay-container').html(file.html());
            $('.overlay-close').on('click', function () {
                self.closeDialog();
            });
        }
    };

    self.loadDialog = function () {
        $.post(file, config.post_data, function (html) {
            var container = $('.overlay-container');
            container.html(html);
            container.find('.overlay-body').css({
                'min-width': '900px',
                'max-width': '1100px'
            });

            $('.overlay-close').on('click', function () {
                self.closeDialog();
            });

            if (container.find('form').attr('action') === '') {
                $('.overlay-container form:first').on('submit', function () {
                    $(this).find('.clone_model').remove();
                    config.post_data = $(this).find('input[type=text], input[type=number], input[type=email], input[type=tel], input[type=date], input[type=hidden], input[type=password], input[type=checkbox]:checked, input[type=radio]:checked, textarea, select').serializeArray();
                    self.loadDialog();
                    return false;
                });
            }
            if (typeof config.callback == 'function') {
                config.callback();
            }

            dialogBodyHeight();
            $('.overlay-container .datepicker').datetimepicker({
                format: 'd.m.Y',
                formatDate: 'd.m.Y',
                timepicker: false,
                lang: 'de'
            });
        });
    };

    self.closeDialog = function () {
        $('.overlay').remove();
        $('embed, object').css('visibility', 'visible');
        $('body').css('overflow', 'auto');
    };

    // Hier gehts los
    if (typeof file == 'object' && $(file).attr('href')) {
        file = $(file).attr('href');
    } else if (typeof file == 'object') {
        file = $(file);
    }

    if (file === 'close') {
        self.closeDialog();
        return;
    }

    if (typeof params != 'object') {
        params = {};
    }

    var defaults = {
        container: 'body',
        callback: function () {
        },
        post_data: {}
    };
    var config = $.extend(defaults, params);

    self.showDialog();

    // ESC Dialog schliessen
    $(document).off('keyup').on('keyup', function (e) {
        if (e.keyCode === 27) {
            self.closeDialog();
        }
    });
};

/**
 * updateListFilter
 * @param object
 * @param json
 * @param listObject
 */
let filterTimeout;
window.updateListFilter = function (object, json, listObject, event) {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(function () {
        listObject.updater(json, event);
    }, 400);

    return filterTimeout;
};