import $ from 'jquery';

// jQuery definitions
window.jQuery = $;
window.$ = $;

// Include custom
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

require('./helper');
require('./additionalFilter');

// Include external libs stored in vendor and np,
require('../vendor/modernizr.touch.svg');

// tinyMCE (via npm)
import tinymce from 'tinymce';
import 'tinymce-i18n/langs/de';

import 'tinymce/themes/modern';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';

// copy tinymce skins to generated folder (js/generated)
require.context(
    '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
    true,
    /.*/
);

// jQuery UI (via vendor and via npm)
require('jquery-ui');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/sortable');
require('jquery-datetimepicker');
require('../vendor/jquery-ui/jquery.iframe-transport');
require('../vendor/jquery-ui/jquery.fileupload');

// XSS warning
console.warn('%cStopp!\nDies ist eine Browser-Funktion für Entwickler. Sollten Sie von jemandem gefragt werden, hier etwas zu kopieren und einzufügen, dann handelt es sich um einen Betrugsversuch.\nWeitere Infos unter https://en.wikipedia.org/wiki/Self-XSS.', 'color: #EA7171');

/**
 * download
 * @param url
 * @param data
 * @param method
 */
$.download = function (url, data, method) {
    // url and data options required
    if (url && data) {
        // data can be string of parameters or array/object
        data = typeof data === 'string' ? data : $.param(data);

        // split params into form inputs
        var inputs = '';
        $.each(data.split('&'), function () {
            var pair = this.split('=');
            inputs += '<input type="hidden" name="' + pair[0] + '" value="' + pair[1] + '" />';
        });

        // send request
        $('<form action="' + url + '" method="' + (method || 'post') + '">' + inputs + '</form>').appendTo('body').submit().remove();
    }
};

/**
 * Switch all Tabs & Divs (Tabnavigation)
 * @param tab
 * @param sec
 * @param savetab
 * @returns {boolean}
 */
window.tabbing = function (tab, sec, savetab) {
    // Switch all Tabs
    $(tab).closest('li').siblings().removeClass('on');
    $(tab).closest('li').addClass('on');
    $(tab).closest('li').siblings().each(function () {
        var link = $(this).find('a').attr('href');
        $(link).removeClass('on');
    });

    $($(tab).attr('href')).addClass('on');

    if (typeof savetab !== 'undefined') {
        $.ajax({
            url: 'ajax/handling.php',
            type: 'post',
            data: {
                lastTab: $(tab).attr('href').replace('#', ''),
                section: sec
            }
        });
    }
    return false;
};

/**
 * Floating Buttons
 */
window.floatingButtons = function () {
    $('.floating-button-holder').each(function () {
        var ele = $(this);
        if (ele.closest('.floating-button-wrap').length) {
            // Wrap Variablen
            var wrap = ele.closest('.floating-button-wrap');
            var wrapOffset = wrap.offset();
            var wrapTop = wrapOffset.top;
            var wrapBot = wrapTop + wrap.outerHeight();
            var wrapLeft = wrapOffset.left;
            var wrapPadTop = parseInt(wrap.css('padding-top'), 10); // Padding des wraps auffangen, damit die Ursprungs-Position des Holders nicht verloren geht
            var wrapPadRight = parseInt(wrap.css('padding-right'), 10);
            var wrapPadBot = parseInt(wrap.css('padding-bottom'), 10);
            var wrapPadLeft = parseInt(wrap.css('padding-left'), 10);

            // Aktuelle Scrollpositionen Variablen
            var curScrollPosTop = $(document).scrollTop();
            var curScrollPosBot = curScrollPosTop + $(window).height();

            // Element Variablen
            var eleHeight = ele.outerHeight();
            var eleTop = wrapTop - curScrollPosTop + wrapPadTop; // Standardgemäß am Elementanfang orientieren

            // Wenn der Element-Anfang im sichtbaren Bereich oder drüber ist
            if (curScrollPosBot >= (wrapTop + eleHeight + wrapPadTop)) {
                eleTop = $(window).height() - eleHeight;
                // Wenn das Ende erreicht ist, muss der holder am Element-Ende angeordnet sein
                if (curScrollPosBot > wrapBot - wrapPadTop) {
                    eleTop = wrapBot - curScrollPosTop - eleHeight - wrapPadBot;
                }
            }

            // Buttons Wrappen und aus dem Wrap einen Platzhalter/Freiraum schaffen
            if (ele.parent('.floating-button-placeholder').length === 0) {
                ele.wrap('<div class="floating-button-placeholder"></div>');
            }
            ele.parent('.floating-button-placeholder').height(eleHeight);

            // Stylings anwenden
            ele.css({
                'position': 'fixed',
                'top': eleTop + 'px',
                'left': (wrapLeft + wrapPadLeft) + 'px',
                'width': (wrap.outerWidth() - wrapPadLeft - wrapPadRight) + 'px',
                'height': eleHeight + 'px',
                'z-index': 5
            });
        }
    });
};

/**
 * Toggle Seen
 * Wechselt Anzeige der gesehenen Mitteilungen
 */
window.toggleSeen = function () {
    var notif = $('.notification-inner');
    if (notif.hasClass('hide-seen')) {
        notif.removeClass('hide-seen');
        $('.show-hidden-notif').html('Gesehene Mitteilungen verbergen');
    } else {
        notif.addClass('hide-seen');
        $('.show-hidden-notif').html('Gesehene Mitteilungen anzeigen');
    }
};

/**
 * Gibt einen numerischen Wert mit n Nachkommastellen aus
 * @param n
 * @returns String
 */
Number.prototype.format = function (n) {
    var re = '\\d(?=(\\d{3})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return num.replace('.', ',').replace(new RegExp(re, 'g'), '$&.');
};

/**
 * Gibt einen String als Zahl aus
 * @returns Number
 */
String.prototype.toNumber = function () {
    var fl = this.replace(/\./g, '').replace(/,/, '.');
    return (+fl);
};

/**
 * SimpleCloner
 * @param clone_obj
 * @param insert_before_element
 * @param disableAutocompleter
 * @returns {jQuery}
 */
window.simpleClone = function (clone_obj, insert_before_element, disableAutocompleter) {
    var clone = $(clone_obj).clone(true).removeClass('clone_model').show();
    var _clone = $(clone);

    var rdm = Math.round(Math.random() * 10e11);
    _clone.attr('id', null);

    _clone.find('input, textarea, select, div, label, datalist').each(function (i, element) {
        // ersetzt clone_model innerhalb von name-, id- und for-Attributen
        ['name', 'id', 'htmlFor'].forEach(function (attribute) {

            var currentAttributeValue = element[attribute];
            if (currentAttributeValue && currentAttributeValue.indexOf('clone_model') > -1) {
                var pattern, replaceWith;

                switch (attribute) {
                    case 'name':
                        pattern = '[clone_model]';
                        replaceWith = '[' + rdm + ']';
                        break;
                    default:

                        pattern = 'clone_model';
                        replaceWith = rdm;
                        break;
                }

                element[attribute] = currentAttributeValue.replace(pattern, replaceWith);
            }
        });

        if (element.classList.contains('err')) {
            element.classList.remove('err');
        }
    });

    // ersetze clone_model für korrektur_buchungen
    _clone.find('.add-correction-box').data('count-position', rdm);

    if (typeof insert_before_element !== 'undefined' && $(insert_before_element).length > 0) {
        $(insert_before_element).before(clone);
        // Anschließend zum neuen Element scrollen
        var pos = _clone.offset();
        $('html,body').animate({ scrollTop: Math.round(pos.top - 20) + 'px' }, 100);
    } else {
        $(clone_obj).before(clone);
    }

    // data-max-Attribut - um nur eine bestimmte Anzahl Klone zu erstellen
    var parent = clone.parent();
    if (parent.attr('data-max') && parent.attr('data-max') <= parent.children('.clone-item').not('.clone_model').length) {
        parent.find('.clone-new').hide();
    }

    // simpleCompleter initialisieren
    if (!disableAutocompleter) {
        _clone.find('.simple-complete-input').simpleCompleter();
    }

    $.each(_clone.find('[title]'), function (i, titleEl) {
        tippy(titleEl, {
            allowHTML: true,
            content (reference) {
                const title = reference.getAttribute('title');
                reference.removeAttribute('title');
                return title;
            },
            onCreate (instance) {
                instance.reference.classList.add('tippy');
            },
        });
    });

    _clone.find('.autocomplete-gmaps').simpleCompleter({
        gmaps: true, onChange: function (obj, place) {
            if (typeof place !== 'undefined') {
                geocode(obj, place.item.data.description);
            }
        }
    });

    // Falls Floating Buttons auf der Seite - Position anpassen
    floatingButtons();

    return clone;
};

/**
 * simpleRemove
 * @param obj
 */
window.simpleRemove = function (obj) {
    var item = $(obj).closest('.clone-item');
    var rows = item.parent().children('.clone-item').not('.clone_model').length;
    if (item.parent().attr('data-max') && item.parent().attr('data-max') > rows - 1) {
        item.parent().find('.clone-new').show();
        item.remove();
    } else if (rows > 0) {
        item.remove();
    } else {
        item.find($('input')).val('');
    }
};

/**
 * Alle bis auf das Letzte Element entfernen
 * @param obj
 */
window.simpleRemoveLeaveOne = function (obj) {
    var item = $(obj).closest('.clone-item');
    var rows = item.parent().children('.clone-item').not('.clone_model').length;
    if (item.parent().attr('data-max') && item.parent().attr('data-max') > rows - 1) {
        item.parent().find('.clone-new').show();
        item.remove();
    } else if (rows > 1) {
        item.remove();
    } else {
        item.find($('input')).val('');
    }
};

/**
 * changeClosestSortInput
 * @param _this
 */
window.changeClosestSortInput = function (_this) {
    var target = $(_this).closest('thead').find('input[data-column="sort"]');
    var currentValue = parseInt(target.val());

    target.val(currentValue === 1 ? 0 : 1);
};

/**
 * Sortierklassen anpassen
 * @param obj
 */
window.sorting = function (obj) {
    var objClasses = obj.classList;

    if (objClasses.contains('sort-desc')) {
        obj.classList.replace('sort-desc', 'sort-asc');
        return;
    }

    if (objClasses.contains('sort-asc')) {
        obj.classList.replace('sort-asc', 'sort-desc');
        return;
    }

    objClasses.add('sort-asc');
    var tr = obj.closest('tr');
    var divs = Array.from(tr.querySelectorAll('div.sorting'));

    divs.forEach(function (div) {
        if (div !== obj) {
            div.classList.remove('sort-desc', 'sort-asc');
        }
    });
};

(function ($) {
    /**
     * Autocompleter dynamisch
     * @param conf
     * @returns {jQuery}
     */
    $.fn.simpleCompleter = function (conf) {
        var defaults = {
            url: '',
            items: '', // Selector für Mehrfachauswahl
            minLength: 0,
            html: true,
            onSelect: function (obj, ui) {
                if ($(obj).prev().is('input')) {
                    $(obj).prev().val(ui.item.id).trigger('change');
                }

                // Redirect page if target exists
                if (typeof ui.item.target !== 'undefined') {
                    window.open(ui.item.target, '_blank');
                }
            },
            onReset: null,
            onChange: null,
            gmaps: false,
            addParams: {}
        };
        var config = $.extend(defaults, conf);

        if (config.gmaps) {

            var service = new google.maps.places.AutocompleteService();

            var countryRestrictions = ['de', 'ch', 'at'];
            var foundLand = false;

            if (this.length > 0) {
                // this ist hier ein object, bestehend aus allen inputs die zum Autocompleten herangezogen werden
                Array.from(this).forEach(function (input) {
                    if (!foundLand) {
                        var closestLand = $(input).closest('.address').find('.land').val();

                        if (closestLand !== undefined) {
                            foundLand = true;
                            closestLand = closestLand.toLowerCase();
                            if (countryRestrictions.indexOf(closestLand) === -1) {
                                countryRestrictions.push(closestLand);
                            }
                        }
                    }
                });
            }

            var s_options = {
                types: ['address'],
                componentRestrictions: { country: countryRestrictions }
            };
        }

        return $(this).not('[data-simple-complete],[readonly]').each(function () {

            var obj = this;
            var $obj = $(obj);

            // Bei Clone-Models nicht ausführen!
            if ($obj.closest('.clone_model').length > 0) {
                return;
            }
            // Bereits initialisierten Autocompleter ignorieren!
            if ($obj.attr('data-simple-complete')) {
                return;
            }

            var options = {
                url: ($obj.data('url') ? $obj.data('url') : config.url),
                items: ($obj.data('items') ? $obj.data('items') : config.items),
                minLength: ($obj.data('minLength') ? $obj.data('minLength') : config.minLength),
                html: ($obj.data('html') ? $obj.data('html') : config.html),
                onSelect: config.onSelect,
                onReset: config.onReset,
                onChange: config.onChange,
                gmaps: config.gmaps,
                addParams: ($obj.data('addparams') ? $obj.data('addparams') : config.addParams)
            };

            // Initialisierung
            $obj.attr('autocomplete', 'no').attr('data-simple-complete', 'active').parent().find('input:not(.no-autowrap)').wrapAll('<div class="autocomplete-wrap"></div>');
            $obj.after('<i class="icon icon-search"></i>');

            // Element löschen
            obj.removeItem = function () {
                if (options.items !== '' && $(options.items).not('.clone_model').length > 1) {
                    $obj.closest('.simple-complete').remove();
                } else {
                    obj.clearItem();
                }
            };

            // Element leeren
            obj.clearItem = function () {
                $(this).val('');
                $(this).prev().val('');
                clear_icon.addClass('icon-search').removeClass('icon-cross');
                // Falls eigenes onChange definiert wurde -> ausführen
                if (typeof options.onChange == 'function') {
                    options.onChange(obj);
                }
                if (typeof obj.onchange == 'function') {
                    obj.onchange();
                }
                if (typeof options.onReset == 'function') {
                    options.onReset(obj);
                }
            };

            var clear_icon = $obj.siblings('.icon');
            clear_icon.on('click', function () {
                obj.removeItem();
            });

            if ($obj.prev().val() > 0) {
                clear_icon.removeClass('icon-search').addClass('icon-cross');
            } else {
                clear_icon.addClass('icon-search').removeClass('icon-cross');
            }

            // Autocompleter erst bei onfocus anlegen
            if (!$obj.hasClass('ui-autocomplete-input')) {
                $(this).autocomplete({
                    source: function (req, add) {
                        // Erweiterung, um Google Places abfragen zu können
                        if (options.gmaps) {
                            if ($obj.val()) {
                                s_options.input = $obj.val();
                                if ($obj.data('gmaps')) {
                                    switch ($obj.data('gmaps')) {
                                        case 'city':
                                            s_options.types = ['(cities)'];
                                            break;
                                        case 'region':
                                            s_options.types = ['(regions)'];
                                            break;
                                        case 'address':
                                            s_options.types = ['address'];
                                            var closestAddress = $obj.closest('.address');

                                            var closestCity = closestAddress.find('.ort').val();
                                            var closestCountry = closestAddress.find('.land').val();

                                            [closestCity, closestCountry].forEach(function (str) {
                                                if (str !== undefined) {
                                                    s_options.input = str + ', ' + s_options.input;
                                                }
                                            });

                                            break;
                                        default:
                                            console.error('gmaps autocomplete error: undefined type');
                                            break;
                                    }
                                }

                                service.getPlacePredictions(s_options, function (places, status) {
                                    var suggestions = [];
                                    $.each(places, function (i, prediction) {
                                        suggestions.push({ label: prediction.description, data: prediction });
                                    });
                                    if (!suggestions) {
                                        suggestions.push({ uid: 0, label: 'Keine Ergebnisse' });
                                    }
                                    add(suggestions);
                                });
                            }
                        } else {
                            if ($(this.element).attr('data-related')) {
                                var related = $(this.element).attr('data-related');
                                if ($(this.element).closest('fieldset').find('.' + related)) {
                                    var r = {};
                                    r[related] = $(this.element).closest('fieldset').find('.' + related).val();
                                }
                                req = $.extend(req, r);
                            }

                            $.getJSON(options.url + (options.url.indexOf('?') !== -1 ? '&' : '?') + $(options.addParams).serialize(), req, function (data) {
                                var suggestions = [];
                                $.each(data, function (i, el) {
                                    suggestions.push(el);
                                });
                                add(suggestions);
                            });
                        }
                    },
                    minLength: options.minLength,
                    html: options.html,
                    select: function (event, ui) {
                        $('.ui-helper-hidden-accessible').remove();

                        if (ui.item.uid != 0) {
                            // Falls "valueLabel" nicht vorhanden, dann Standard "label" einsetzen
                            $(this).val(typeof ui.item.valueLabel != 'undefined' ? ui.item.valueLabel : ui.item.label);
                            if (typeof ui.item.uid != 'undefined') {
                                $(this).prev().val(ui.item.uid);
                            }
                            clear_icon.removeClass('icon-search').addClass('icon-cross');
                        } else {
                            obj.clearItem();
                        }

                        // Falls eigenes onChange definiert wurde -> ausführen
                        if (typeof options.onChange == 'function') {
                            options.onChange(obj, ui);
                        }

                        if (typeof options.onSelect == 'function') {
                            options.onSelect(obj, ui);
                        }

                        // Falls eigenes onchange definiert wurde -> ausführen
                        if (typeof obj.onchange == 'function') {
                            obj.onchange(ui);
                        }

                        return false;
                    },
                    focus: function () {
                        return false;
                    }
                }).on('focus', function () {
                    $(this).autocomplete('search', $(this).val());
                    $(this).attr('autocomplete', 'no');
                }).on('change blur', function () {
                    if ($(this).val() === '') {
                        obj.clearItem();
                    }
                    $(this).attr('autocomplete', 'no');
                }).data('ui-autocomplete')._renderItem = function (ul, item) {
                    return $('<li>').append('<a>' + item.label + '</a>').appendTo(ul);
                };
            }
        });
    };
})(jQuery);

/**
 * Ermitteln und setzen der Höhe des Bodys jedes Dialogs, damit bei Bedarf gescrollt werden kann
 */
window.dialogBodyHeight = function () {
    var body = $('.overlay-body');
    if (body.length > 0) {
        body.css('max-height', '80%');
    }
};

/**
 * Macht einen Listen-Bereich sortierbar, seitenschaltbar usw.
 * @param item
 * @param get_data
 * @param params
 * @param special
 */
window.ajaxItem = function (item, get_data, params, special) {
    if (typeof item != 'string') {
        return;
    }
    if (typeof params != 'object') {
        params = {};
    }

    get_data = get_data || {};
    const self = this;
    var container_list = '#list_' + item;
    var ajax_file = 'ajax/lists/' + item + '.php';

    if (special) {
        container_list = '#list_special_' + special;
        if (special === 'statistic') {
            container_list = '#statistic_' + item;
            ajax_file = 'ajax/graph/' + item + '.php';
        }
    }

    var defaults = {
        container: container_list,
        ajax_file: ajax_file,
        callback: function () {
        }
    };
    var config = $.extend(defaults, params);

    this.searchTimeout = undefined;

    /**
     * sortBy
     * @param sortby
     */
    this.sortBy = function (sortby) {
        self.updater({ sortby: sortby });
    };

    /**
     * changePage
     * @param page
     */
    this.changePage = function (page) {
        self.updater({ page: page });
    };

    /**
     * addFilter
     * Fügt weitere Elemente aus einem Object hinzu, um diese mit einem späteren Aufruf von updater() zu berücksichtigen
     * @param name
     * @param filter
     */
    this.addFilter = function (name, filter) {
        if (typeof filter == 'object') {
            var filt = [];
            $(filter).each(function () {
                filt[filt.length] = $(this).val();
            });
            var temp = {};
            temp[name] = filt;
            get_data = $.extend({}, get_data, temp);
        }
    };

    this.loader = function () {
        $.ajax({
            url: config.ajax_file,
            beforeSend: function () {
                if (get_data.showLoader === true) {
                    $(config.container).html('<div class="section-content"><div class="section-head"><div class="loader"><span></span><span></span><span></span></div> <h2>Wird geladen...</h2></div></div><div></div>');
                } else if (get_data.showLoaderList === true) {
                    $(config.container).html('<tr><td colspan="99"><div class="loader-wrap"><div class="loader"><span></span><span></span><span></span></div></div><h2></h2></td></tr>');
                    $(config.container).closest('table.list.sortable').find('thead').addClass('disabled');
                } else {
                    self.showLoader();
                }
            },
            success: function (html) {
                $(config.container).html(html);
                $(config.container).closest('table.list.sortable').find('thead').removeClass('disabled');
                self.afterLoad();

                tippy('[title]', {
                    size: 'large',
                    arrow: true,
                    allowHTML: true,
                    content (reference) {
                        const title = reference.getAttribute('title');
                        reference.removeAttribute('title');
                        return title;
                    },
                    onCreate (instance) {
                        instance.reference.classList.add('tippy');
                    },
                });
            }
        });
    };

    /**
     * Updater
     * @param get_updater
     * @param event
     */
    this.updater = function (get_updater, event) {
        // update only when button is "enter"
        if (event !== undefined && event.keyCode !== 13) {
            return false;
        }

        var _this = this;

        var data = (typeof get_updater != 'undefined' ? get_updater : {});

        // andere Spalten berücksichtigen; müssen onkeyup und data-column Attribute haben
        $.each($(container_list).parent().find('thead input[onkeyup][data-column]'), function (i, input) {
            data[input.dataset.column] = this.value;
        });

        data['ajax'] = 1;

        $.extend(data, get_data);

        if (_this.searchTimeout !== undefined) {
            clearTimeout(_this.searchTimeout);
        }

        _this.searchTimeout = setTimeout(function () {
            $.ajax({
                url: config.ajax_file,
                type: 'get',
                data: data,
                beforeSend: function () {
                    $('input').attr('disabled', true);

                    if (get_data.showLoader === true) {
                        $(config.container).html('<div class="section-content"><div class="section-head"><div class="loader"><span></span><span></span><span></span></div> <h2>Wird geladen...</h2></div></div><div></div>');
                    } else if (get_data.showLoaderList === true) {
                        $(config.container).html('<tr><td colspan="99"><div class="loader-wrap"><div class="loader"><span></span><span></span><span></span></div></div><h2></h2></td></tr>');
                    } else {
                        self.showLoader();
                    }
                },
                success: function (html) {
                    $(config.container).html(html);
                    self.afterLoad();

                    tippy('[title]', {
                        size: 'large',
                        arrow: true,
                        allowHTML: true,
                        content (reference) {
                            const title = reference.getAttribute('title');
                            reference.removeAttribute('title');
                            return title;
                        },
                        onCreate (instance) {
                            instance.reference.classList.add('tippy');
                        },
                    });

                    clearTimeout(_this.searchTimeout);
                    $('input').attr('disabled', false);
                }
            });

        }, 300);
    };

    /**
     * Poster
     * @param post_data
     */
    this.poster = function (post_data) {
        var data = (typeof post_data != 'undefined' ? post_data : {});
        data = $.extend(data, { ajax: 1 });
        $.ajax({
            url: config.ajax_file + '?' + $.param(get_data),
            type: 'post',
            data: data,
            beforeSend: function () {
                if (get_data.showLoader === true) {
                    $(config.container).html('<div class="section-content"><div class="section-head"><div class="loader"><span></span><span></span><span></span></div> <h2>Wird geladen...</h2></div></div><div></div>');
                } else if (get_data.showLoaderList === true) {
                    $(config.container).html('<tr><td colspan="99"><div class="loader-wrap"><div class="loader"><span></span><span></span><span></span></div></div><h2></h2></td></tr>');
                } else {
                    self.showLoader();
                }
            },
            success: function (html) {
                $(config.container).html(html);
                self.afterLoad();
            }
        });
    };

    /**
     * afterLoad
     */
    this.afterLoad = function () {
        if (typeof config.callback == 'function') {
            config.callback();
        }
    };

    /**
     * showLoader
     */
    this.showLoader = function () {
        insertAutoLoader($(config.container));
    };
};

/**
 * Prüft ob andere Checkboxen gesetzt werden dürfen
 * @param obj
 */
window.checkPerm = function (obj) {
    if (!$(obj).prop('checked')) {
        $(obj).closest('tr').find('input.role-edit, input.role-delete').prop('checked', false).attr('disabled', 'disabled');
    } else {
        $(obj).closest('tr').find('input.role-edit, input.role-delete').removeAttr('disabled', 'disabled');
    }
};

/**
 * Pruefen ob Feld veraendert wurde
 */
window.getFormValues = function () {
    var form = $('#form');
    var formInputs = $('#form input:not([type=hidden]), #form select, #form textarea');
    var formObj = {};

    if (form != null) {
        formInputs.each(function (i) {
            if ($(this).prop('type') === 'checkbox' || $(this).prop('type') === 'radio') {
                if ($(this).val() !== 'undefined') {
                    formObj[i] = $(this).prop('checked');
                }
            } else if ($(this).prop('type') === 'select-multiple') {
                var selectBox = $(this);
                for (var i = 0; i < selectBox.length; i++) {
                    if (selectBox[i].prop('selected') === true) {
                        formObj[i] = selectBox[i].text();
                    }
                }
            } else {
                if ($(this).val() !== 'undefined') {
                    formObj[i] = $(this).val();
                }
            }
        });
    }

    // Sortieren des Json Objekts
    var values = [];
    $.each(formObj, function (index, value) {
        values[index] = value;
    });
    values.sort();

    return $.trim(values);
};

/*
 * Fixierung der Sidebar, wenn die Klasse is-fixed gesetzt ist
 */
window.fixedSidebar = function () {
    var sectionSidebarFixed = $('.section-sidebar.is-fixed');
    if (sectionSidebarFixed.length) {
        if ($(window).width() > 1280) {
            // Werte holen
            var header = $('#header');
            var mainContent = $('#maincontent');

            var curScrollPosTop = $(document).scrollTop() + header.outerHeight() + 20;
            var maxSidebarHeight = $(window).height() - header.outerHeight() - 40;
            var maincontentOffsetTop = mainContent.offset().top;
            var posRight = ($(window).width() - (mainContent.offset().left + mainContent.outerWidth()));
            // Scrollbar machen
            sectionSidebarFixed.css({
                'max-height': maxSidebarHeight + 'px',
                'overflow-y': 'auto'
            });
            // Fixierung
            if (curScrollPosTop > maincontentOffsetTop) {
                sectionSidebarFixed.css({
                    'position': 'fixed',
                    'top': (maincontentOffsetTop - header.outerHeight() - 20) + 'px',
                    'right': posRight + 'px'
                });
            } else {
                // Element resetten
                sectionSidebarFixed.removeAttr('style');
            }
        } else {
            // Element resetten
            sectionSidebarFixed.removeAttr('style');
        }
    }
};

/**
 * copyOnClick
 */
window.copyOnClick = function () {
    var _this = this;

    $(_this).off('click');

    var text = _this.innerText;

    var input = document.createElement('input');
    input.value = text;

    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.remove();

    _this.innerHTML = text + ' <span style="color: green;">kopiert!</span>';

    setTimeout(function () {
        _this.innerText = text;
        $(_this).on('click', copyOnClick);
    }, 2000);
};

/**
 * Optionale Bereiche in der Eingabe
 * @param obj
 */
window.optionalTrigger = function (obj) {
    var optionalContent = $(obj).closest('.optional-container').find('.optional-content');
    if ($(obj).is(':checked')) {
        if ($(obj).hasClass('reverse')) {
            optionalContent.hide();
        } else {
            optionalContent.show();
        }
    } else {
        if ($(obj).hasClass('reverse')) {
            optionalContent.show();
        } else {
            optionalContent.hide();
        }
    }
};

/**
 * foldableFieldsets
 */
window.foldableFieldsets = function () {
    $('fieldset').not('.nofold').each(function () {
        var wrap = $(this);
        var header = wrap.children('legend');
        wrap.children().not(':first-child').wrapAll('<div class="fieldset-content" />');
        var content = wrap.children('.fieldset-content');
        if (wrap.hasClass('closed')) {
            content.hide();
        }
        header.on('click', function () {
            if (wrap.hasClass('closed')) {
                wrap.removeClass('closed');
                content.stop().slideDown(250);
            } else {
                wrap.addClass('closed');
                content.stop().slideUp(250);
            }
        });
    });
};

/**
 * toggleArea
 * @param container
 * @param name
 * @param on
 */
window.toggleArea = function (container, name, on) {
    if (on) {
        $(container).find('.when-' + name).show();
        $(container).find('.when-not-' + name).hide();
    } else {
        $(container).find('.when-' + name).hide();
        $(container).find('.when-not-' + name).show();
    }
};

//////////////////// GEO / GMAPS - Funktionen ///////////////////////////
var geocoder,
    componentForm = {
        street_number: 'str_nr',
        route: 'strasse',
        political: 'strasse',
        locality: 'ort',
        country: 'land',
        postal_code: 'plz'
    };

/**
 * initAutocomplete
 */
window.initAutocomplete = function () {
    $(document).ready(function () {
        geocoder = new google.maps.Geocoder();

        $('.autocomplete-gmaps').simpleCompleter({
            gmaps: true,
            onChange: function (obj, place) {
                if (place && place.item && place.item.data) {
                    geocode(obj, place.item.data.description);
                }
            }
        });

        $('.str_nr').on('change', function () {
            var addressArea = $(this).closest('.address');

            var strasse = addressArea.find('.strasse');
            var ort = addressArea.find('.ort');
            var land = addressArea.find('.land');

            if (strasse && ort && land) {
                geocode(this, strasse.val() + ' ' + addressArea.find('.str_nr').val() + ', ' + ort.val() + ', ' + land.val());
            }
        });
    });
};

/**
 * fillInAddress
 * @param obj
 * @param place
 */
window.fillInAddress = function (obj, place) {
    if (place && place.address_components) {

        var address_area = $(obj).closest('.address');

        var c = {
            str_nr: null,
            strasse: null,
            ort: null,
            land: null,
            plz: null
        };

        $(place.address_components).each(function () {
            var address_type = this.types[0];
            if (componentForm[address_type]) {
                var val = address_type === 'country' ? this.short_name : this.long_name;
                if (!c[componentForm[address_type]]) {
                    c[componentForm[address_type]] = val;
                }
            }
        });

        var plz_replaced = false;
        $.each(c, function (index, value) {
            var field = $(address_area.find('.' + index));

            // str_nr nicht einfach überschreiben, wenn schon was drin steht

            if (!field.hasClass('str_nr') || !field.val()) {
                field.val(value);
            }

            if (field.hasClass('plz')) {
                plz_replaced = true;
            }
        });

        // Wenn wir keine PLZ zurückbekommen haben
        if (!plz_replaced) {
            if (address_area.find('.str_nr').val()) {

                address_area.find('.str_nr').trigger('change');
            } else {
                $(obj).closest('.address').find('.plz').val('');
            }
        }
    }
};

/**
 * geocode
 * @param obj
 * @param str
 */
window.geocode = function (obj, str) {
    geocoder.geocode({
        'address': str
    }, function (results, status) {

        if (results[0]) {
            fillInAddress(obj, results[0]);
        }
    });
};

/**
 * updateCounter
 * @param target
 * @param num
 */
window.updateCounter = function (target, num) {
    var numTarget = $('#unread-' + target);
    var method = undefined;

    if (num > 0) {
        numTarget.show().text(num);
        method = 'addClass';
    } else {
        numTarget.hide();
        method = 'removeClass';
    }

    if (method !== undefined) {
        $('[data-notification-type="' + target + '"]')[method]('new_notif');
    }
};

/**
 * updateNotifications
 */
window.updateNotifications = function () {
    $.ajax({
        url: 'ajax/update_notification.php',
        dataType: 'json',
        async: true,
        success: function (response) {
            ['msg', 'calls'].forEach(function (index) {
                var unreadTarget = $('#unread-' + index);
                var currentNotifs = parseInt(unreadTarget.text());
                var classMethod = undefined;

                if (currentNotifs !== response[index] && response[index] > 0) {
                    var list = undefined;
                    if (index === 'msg' && typeof NotificationList !== 'undefined') {
                        list = NotificationList;
                    } else if (index === 'calls' && typeof CallList !== 'undefined') {
                        list = CallList;
                    }
                    if (typeof list !== 'undefined') {
                        list.updater();
                    }

                    classMethod = 'addClass';
                    unreadTarget.show().text(response[index]);
                } else if (response[index] === 0) {
                    classMethod = 'removeClass';
                    unreadTarget.hide();
                }

                if (classMethod !== undefined) {
                    $('[data-notification-type="' + index + '"]')[classMethod]('new_notif');
                }
            });
        }
    });
};

/**
 * acknowledgeCall
 * @param element
 * @param callID
 */
window.acknowledgeCall = function (element, callID) {
    var $element = $(element);
    var payload = {
        call: callID
    };

    $.post('ajax/acknowledge_call.php', payload, function (response) {
        if (response.success) {
            $element.parent().remove();

            var unreadCallsEl = $('#unread-calls');

            var previousAmount = parseInt(unreadCallsEl.text());
            var nextAmount = previousAmount - 1;
            unreadCallsEl.text(nextAmount);

            if (nextAmount === 0) {
                unreadCallsEl.hide();
                unreadCallsEl.parent().removeClass('new_notif');
            }
        }
    });
};

/**
 * toggleDashboardExpansion
 * @param el
 * @param customID
 */
window.toggleDashboardExpansion = function (el, customID) {
    var _this = el ? $(el) : $(this);
    var payload = {};
    var method = _this.hasClass('open') ? 'removeClass' : 'addClass';
    var parentID = _this.parent().parent().attr('id');
    if (customID) {
        parentID = customID;
    }

    var now = Date.now();
    var lastFired = _this.data('last-fired') ? _this.data('last-fired') : 0;

    if (parentID !== undefined && now - 100 > lastFired) {
        _this.data('last-fired', now);

        payload[parentID] = _this.hasClass('open') ? 'close' : 'open';

        $.get('ajax/handling.php', payload);
        _this[method]('open');
    }
};

/**
 *
 * @param amount
 * @param decimalCount
 * @param decimal
 * @param thousands
 * @returns {string}
 */
window.formatMoney = function (amount, decimalCount, decimal, thousands) {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        var negativeSign = amount < 0 ? '-' : '';

        var i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        var j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
    } catch (e) {
        console.error(e);
    }
};

/**
 * insertUploadedZahlungen
 * @param zahlungen
 */
window.insertUploadedZahlungen = function (zahlungen) {
    'use strict';

    if (!zahlungen || zahlungen.length === 0) {
        zahlungen = { error: 'Ungültige CSV-Datei!' };
    }

    document.getElementById('clone-initiator').style.display = 'none';

    if (zahlungen.length > 0 && !zahlungen.error) {

        // key = Feldname im HTML, value = Object property von zahlungen, vorgegeben durch $csvContent
        // noinspection NonAsciiCharacters
        var dataFieldConnection = {
            'betrag': 'Betrag',
            'absender': 'Beguenstigter/Zahlungspflichtiger',
            'datum': 'Buchungstag',
            'verwendungszweck': 'Verwendungszweck',
            'valutadatum': 'Valutadatum',
            'info': 'Info',
            'buchungstext': 'Buchungstext',
            'währung': 'Waehrung',
            'isImportedViaBank': 'isImportedViaBank',
            'md5': 'md5',
            'iban': 'Kontonummer/IBAN',
            'bic': 'BIC (SWIFT-Code)',
            'akte': 'akte',
            'akte_uid': 'akte_uid'
        };

        var relevantFields = Object.keys(dataFieldConnection);
        var correspondingValue = polyfillObjectValues(dataFieldConnection);
        var cloneParent = $('#zahlungseingänge .clone_model');
        var readonlyFields = ['betrag', 'datum', 'absender', 'verwendungszweck'];

        $.each(zahlungen, function (i, zahlung) {
            var tr = simpleClone(cloneParent, undefined, true);

            $.each(tr.find('input, textarea'), function (k, element) {
                var _element = $(element);

                relevantFields.forEach(function (name) {
                    if (element.name.indexOf(name) > -1) {

                        var value = zahlung[correspondingValue[relevantFields.indexOf(name)]];

                        if (name === 'betrag') {
                            value = formatMoney(value, 2, ',', '.');
                        }

                        _element.val(value);

                        // Wert darf nicht nachträglich veränderbar sein
                        if (readonlyFields.includes(name)) {
                            _element.attr('readonly', true);
                        }
                    }
                });
            });
        });

        $('input[name*="[akte]"]').simpleCompleter();
        $('input[name*="[datum]"]').next('.input-group-append').remove();
        $('input[name*="[absender_uid]"]').remove();
    } else {
        alert(zahlungen.error);
    }
};

/**
 * polyfillObjectValues
 * @param obj
 * @returns {[]}
 */
window.polyfillObjectValues = function (obj) {
    var res = [];
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            res.push(obj[i]);
        }
    }
    return res;
};

/**
 * b64EncodeUnicode
 * @param str
 * @returns {string}
 */
window.b64EncodeUnicode = function (str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }));
};

/**
 * createZeugenPDFData
 * @param zeugentyp
 * @param currentKey
 * @param akteUID
 */
window.createZeugenPDFData = function (zeugentyp, currentKey, akteUID) {

    var data = {

        'akte_uid': akteUID,
        'vorname': $('#vorname_' + zeugentyp + '_' + currentKey).val(),
        'nachname': $('#nachname_' + zeugentyp + '_' + currentKey).val(),
        'strasse': $('#strasse_' + zeugentyp + '_' + currentKey).val(),
        'str_nr': $('#str_nr_' + zeugentyp + '_' + currentKey).val(),
        'plz': $('#plz_' + zeugentyp + '_' + currentKey).val(),
        'ort': $('#ort_' + zeugentyp + '_' + currentKey).val(),
        'land': $('#land_' + zeugentyp + '_' + currentKey).val(),
        'telefon': $('#telefon_' + zeugentyp + '_' + currentKey).val(),
        'email': $('#email_' + zeugentyp + '_' + currentKey).val(),
        'kommentar': $('#kommentar_' + zeugentyp + '_' + currentKey).val(),
        'beobachtung': $('#beobachtung_0_' + zeugentyp + '_' + currentKey).is(':checked') ? 0 : 1,
        'verwandtschaft': $('#verwandtschaft_0_' + zeugentyp + '_' + currentKey).is(':checked') ? 0 : 1
    };

    // mask url parameters
    var json = JSON.stringify(data);
    var btoaData = b64EncodeUnicode(json);

    var url = 'ajax/pdf_export/zeugenbericht.php?data=' + btoaData;
    window.open(url, '_blank');
};

var tinyMCEConfigArray = {
    // General options
    mode: 'specific_textareas',
    editor_selector: 'mceEditor',
    theme: 'modern',
    height: '100%',
    language: 'de',
    // Dieses Plugin wird benoetigt um das Einfügen von Word-Inhalten zu reinigen
    plugins: 'paste,table,autoresize',
    // Theme options
    theme_advanced_buttons1: 'bold,italic,underline,|,fontsizeselect,|,justifyleft,justifycenter,justifyright,justifyfull,|,bullist,numlist,|,removeformat,|,table,row_before,row_after,delete_row,col_before,col_after,delete_col,delete_table', // split_cells,merge_cells
    theme_advanced_buttons2: false,
    theme_advanced_buttons3: false,
    theme_advanced_buttons4: false,
    theme_advanced_toolbar_location: 'top',
    theme_advanced_toolbar_align: 'left',
    theme_advanced_resizing: false,
    table_default_cellpadding: 1,
    table_default_cellspacing: 0,
    valid_elements: '@[style|cellpadding|cellspacing|border],strong/b,em/i,strike,u,span,br,p,ul,ol,li,table,tr,td',
    resize: false,
    setup: function (editor) {
        tinyMCEEditor = editor;
        editor.on('keyup', function () {
            if (typeof toggleSubmitButton !== undefined) {
                toggleSubmitButton();
            }

        });

        editor.on('keyup, change', function () {
            // Jede Änderung im Editor in der (versteckten) textarea austauschen
            $('textarea.mceEditor').text(editor.getContent());
        });
    }
};

var tinyMCEEditor;

/**
 * initMCE
 * @returns {tinymce}
 */
window.initMCE = function () {
    tinymce.remove();
    tinymce.init(tinyMCEConfigArray);

    const currentAkteUID = extractAkteUIDFromUrl();
    const showTextModules = $('.mceEditor').data('show-text-modules');

    if (currentAkteUID > 0 && showTextModules) {
        setTimeout(function () {
            $.getJSON('ajax/getTextModules.php?akteUID=' + currentAkteUID, {}, function (textbausteine) {
                initTextbausteine(textbausteine);
            });
        }, 200);
    }

    return tinymce;
};

/**
 * extractAkteUIDFromUrl
 * @returns {number}
 */
window.extractAkteUIDFromUrl = function () {
    var akteUID = 0;

    location.search.substr(1).split('&').forEach(function (getParam) {

        var parts = getParam.split('=');

        if (parts.length === 2) {
            if (parts[0].indexOf('akte') > -1) {
                akteUID = parts[1];
            }
        }
    });

    return akteUID;
};

/**
 * renderTextbausteine
 * @param textbausteine
 * @returns {any}
 */
window.renderTextbausteine = function (textbausteine) {
    var wrap = crEl('div');
    wrap.classList.add('textmodules-container');

    textbausteine.forEach(function (textbaustein) {
        var a = crEl('a');
        a.dataset.uid = textbaustein.uid;
        a.dataset.akteUid = textbaustein.akteUID;
        a.innerText = textbaustein.title;
        a.href = '#';
        a.classList.add('mce-menu-item');

        a.addEventListener('click', function (event) {
            event.preventDefault();
            insertTextModule(a.dataset.uid, a.dataset.akteUid);
        });

        if (typeof toggleSubmitButton !== undefined) {
            a.addEventListener('click', toggleSubmitButton);
        }

        wrap.appendChild(a);
    });

    return wrap;
};

/**
 * insertTextModule
 * @param module_id
 * @param akte_id
 */
window.insertTextModule = function (module_id, akte_id) {
    $.getJSON('ajax/handle_text_modules.php?module_id=' + module_id + '&akte_id=' + akte_id, function (response) {
        tinymce.execCommand('mceInsertContent', false, response);
    });
};

/**
 * crEl
 * @param tag
 * @returns {any}
 */
window.crEl = function (tag) {
    return document.createElement(tag);
};

/**
 * initTextbausteine
 * @param textbausteine
 */
window.initTextbausteine = function (textbausteine) {
    var lastMCEMenuItem = document.querySelector('.mce-menubar .mce-last');

    if (lastMCEMenuItem) {
        lastMCEMenuItem.classList.remove('mce-last');
    }

    var wrap = crEl('div');
    wrap.classList.add('mce-widget', 'mce-btn', 'mce-menubtn', 'mce-flow-layout-item', 'mce-btn-has-text', 'mce-last', 'textmodules');
    wrap.style.float = 'right';

    var button = crEl('button');
    button.type = 'button';
    button.addEventListener('click', function () {
        var textModulesContainer = document.querySelector('.textmodules-container');

        textModulesContainer.style.display = textModulesContainer.style.display === 'block' ? 'none' : 'block';
    });

    var span = crEl('span');
    span.innerText = 'Textbausteine';
    span.classList.add('mce-txt');

    var caretIcon = crEl('i');
    caretIcon.classList.add('mce-caret');
    caretIcon.style.marginLeft = '3px';

    button.appendChild(span);
    button.appendChild(caretIcon);
    wrap.appendChild(button);
    wrap.appendChild(renderTextbausteine(textbausteine));

    if (lastMCEMenuItem) {
        lastMCEMenuItem.parentNode.insertBefore(wrap, lastMCEMenuItem.nextSibling);
    }
};

/**
 * Tiny
 * @constructor
 */
window.Tiny = function () {
    var activeEditors = [];

    this.activateEditor = function (id) {
        activeEditors[activeEditors.length] = id;
        toggleEditor(id);
    };

    this.deactivateEditors = function () {
        for (x = 0; x < activeEditors.length; x++) {
            toggleEditor(activeEditors[x]);
        }

        activeEditors.length = 0;
    };

    this.toggleEditor = function (id, value) {
        tinymce.init(tinyMCEConfigArray);
        var elm = tinymce.get(id);

        if (value == null) {
            value = '';
        }

        if (elm == null) {
            elm.execCommand('mceAddControl', false, id);

            //Inhalt setzen
            elm.execCommand('mceInsertContent', false, value);
        } else {
            elm.execCommand('mceRemoveControl', false, id);
            elm.execCommand('mceInsertContent', false, value);
        }
    };
};

/**
 * addPDFListeners
 * @param btn
 * @param akteUID
 */
window.addPDFListeners = function (btn, akteUID) {
    var _btn = $(btn);

    var currentKey = _btn.closest('div').find('input[type="hidden"]').attr('name').replace('[', '').replace(']', '');
    var currentZeugentyp = _btn.data('zeugentyp');
    var pdfType = _btn.data('pdf-type');

    switch (pdfType) {
        case 'verletztenfragebogen':
            createVerletztenPDFData(currentZeugentyp, currentKey, akteUID);
            break;
        case 'zeugenbericht':
            createZeugenPDFData(currentZeugentyp, currentKey, akteUID);
            break;
        default:
            console.error('unknown pdfType: ' + pdfType);
            break;
    }
};

/**
 * disableAutocomplete
 */
window.disableAutocomplete = function () {
    $('form').attr('autocomplete', 'off');

    $('input').each(function (i, input) {
        var $input = $(input);

        if (!$input.prop('readonly') && !$input.prop('disabled') && !$input.attr('autocomplete') && !$input.data('gmaps')) {
            $input.attr('autocomplete', 'off');
        }
    });
};

/**
 * hiddenInputDeleteListenerTarget
 * @param e
 * @param _this
 * @param targetName
 */
window.hiddenInputDeleteListenerTarget = function (e, _this, targetName) {
    var $this = _this;
    var $thisValue = $this.val();

    // Löschbutton
    var button = $this.next().find('button');
    // unsichtbares Input das die ID zum posten mitführt
    var hiddenInput = $('[name="' + targetName + '"]');

    // Löschbutton deaktiviert solange Input leer ist
    button.prop('disabled', $thisValue.length === 0);

    // eventListener für Löschbutton nur hinzufügen sobald Input auch einen Wert hat
    button.on('click', function () {
        $this.val('').trigger('input');
        hiddenInput.val('').trigger('change');
        button.off('click', this);
    });

    $('#' + $this.attr('list')).find('option').each(function () {
        if ($(this).val() === $thisValue) {
            hiddenInput.val($(this).data('uid'));
        }
    });
};

/**
 * hiddenInputDeleteListener
 */
window.hiddenInputDeleteListener = function () {
    var $this = $(this);
    var $thisValue = $this.val();

    // Löschbutton
    var button = $this.next().find('button');
    // unsichtbares Input das die ID zum posten mitführt
    var hiddenInput = $('[name="a[' + $this.attr('id') + ']"]');

    // Löschbutton deaktiviert solange Input leer ist
    button.prop('disabled', $thisValue.length === 0);

    // eventListener für Löschbutton nur hinzufügen sobald Input auch einen Wert hat
    button.on('click', function () {
        $this.val('').trigger('input');
        hiddenInput.val('').trigger('change');
        button.off('click', this);
    });

    $('#' + $this.attr('list')).find('option').each(function () {
        if ($(this).val() === $thisValue) {
            hiddenInput.val($(this).data('uid'));
        }
    });
};

/**
 * formatIBAN
 */
window.formatIBAN = function () {
    var arr = this.value.split('');
    var count = 1;
    var iban = '';

    for (index in arr) {
        if (count <= 2) {

            arr[index] = arr[index].toUpperCase();
            if (arr[index].search(/^[A-Z]+$/) === -1) {
                break;
            }
        } else {
            if (arr[index].search(/^[0-9]+$/) === -1) {
                continue;
            }
        }

        iban += arr[index];

        if (count % 4 === 0) {
            iban += ' ';
        }
        ++count;
    }

    this.value = iban;
};

/**
 * Versicherungsliste
 * @param obj
 */
window.getInsuranceList = function (obj) {
    var insurance = $(obj).closest('div.inner-versicherung').find('.insurance');
    insurance.removeAttr('disabled');
    insurance.val('');

    var insuranceList = $(obj).closest('div.inner-versicherung').find('.insurance-list');
    insuranceList.empty();
    insurance.attr('list', insuranceList.attr('id'));

    insurance.next().find('button').on('click', function () {
        $(obj).closest('div.inner-versicherung').find('input').not(insurance).prop('disabled', true).val('');
    });

    $.getJSON('ajax/get_insurance_list.php?insuranceType=' + $(obj).val(), function (data) {
        if ($(data).length > 0) {
            insurance.on('input', hiddenInputDeleteListenerClone);
            $.each(data, function (i, item) {
                insuranceList.append('<option data-uid="' + item.uid + '">' + item.firma + '</option>');
            });
        } else {
            $(obj).closest('div.inner-versicherung').find('input').not(insurance).prop('disabled', true).val('');
        }
    });
};

/**
 * Versicherungsdaten
 * @param obj
 */
window.getInsuranceData = function (obj) {
    if ($(obj).val() !== '') {
        $(obj).closest('div.inner-versicherung').find('input:disabled').prop('disabled', false);
    }
};

/**
 * Ansprechpartnerliste anhand der ausgewählten Versicherung
 * @param obj
 */
window.getContactList = function (obj) {
    var contact = $(obj).closest('div.inner-versicherung').find('.contact');
    contact.removeAttr('disabled');
    contact.val('');

    var contactList = $(obj).closest('div.inner-versicherung').find('.contact-list');
    contactList.empty();
    contact.attr('list', contactList.attr('id'));

    contact.next().find('button').on('click', function () {
        $(obj).closest('div.inner-versicherung').find('input').not(contact).prop('disabled', true).val('');
    });

    var hiddenInput = $('[name="' + $(obj).attr('id') + '"');

    $.getJSON('ajax/get_contact_list_by_insurance.php?insuranceUID=' + hiddenInput.val(), function (data) {
        if ($(data).length > 0) {
            contact.on('input', hiddenInputDeleteListenerClone);
            $.each(data, function (i, item) {
                contactList.append('<option data-uid="' + item.uid + '">' + item.firstname + ' ' + item.lastname + '</option>');
            });
        }
    });
};

/**
 * Listener für Clone-Modelle
 */
window.hiddenInputDeleteListenerClone = function () {
    var $this = $(this);
    var $thisValue = $this.val();

    // Löschbutton
    var button = $this.next().find('button');
    // unsichtbares Input das die ID zum posten mitführt
    var hiddenInput = $('[name="' + $this.attr('id') + '"');

    // Löschbutton deaktiviert solange Input leer ist
    button.prop('disabled', $thisValue.length === 0);

    // eventListener für Löschbutton nur hinzufügen sobald Input auch einen Wert hat
    button.on('click', function () {
        $this.val('').trigger('input');
        hiddenInput.val('').trigger('change');

        if (!$this.hasClass('contact')) {
            $this.closest('div.inner-versicherung').find('input').not($this).prop('disabled', true).val('');
        }
        button.off('click', this);
    });

    $this.closest('div.inner-versicherung').find('datalist').find('option').each(function () {
        if ($(this).val() === $thisValue) {
            hiddenInput.val($(this).data('uid'));
        }
    });
};

/**
 * buildKollisionTR
 * @param type
 * @param dataset
 * @returns {string}
 */
window.buildKollisionTR = function (type, dataset) {
    var tr = '<tr data-uid="' + dataset.uid + '">' +
        '<td>' + type + '</td>';

    tr += dataset.name ? '<td>' + dataset.name + '</td>' : '<td></td>';

    if (typeof dataset.address === 'object') {
        tr += '<td><span title="' + dataset.address.join('<br>') + '">' + dataset.address.length + ' Adresse(n)</span></td>';
    } else if (dataset.address) {
        tr += '<td>' + dataset.address + '</td>';
    } else {
        tr += '<td></td>';
    }

    tr += dataset.rubri ? '<td><span title="' + dataset.rubri.join('<br>') + '">in ' + dataset.rubri.length + ' Akte(n)</span></td>' : '<td></td>';

    tr += dataset.kennzeichen ? '<td>' + dataset.kennzeichen + '</td>' : '<td></td>';

    tr += '</tr>';

    return tr;
};

/**
 * kollisionsabfrage
 * @param data
 * @param mayViewResults
 */
window.kollisionsabfrage = function (data, mayViewResults) {
    var submitButton = $('.overlay-footer button[type="submit"]');

    $.getJSON('ajax/kollisionsabfrage.php', data, function (response) {
        var kollisionsabfrageContainer = $('#kollisionsabfrage');

        if (response.suspects > 0) {
            var kollisionsTBody = $('#kollisionen tbody');

            kollisionsTBody.empty();
            kollisionsabfrageContainer.show();
            kollisionsabfrageContainer[0].scrollIntoView();

            if (mayViewResults) {
                // noinspection NonAsciiCharacters
                var iterationObj = {
                    Schädiger: response.gegner,
                    Mandant: response.mandant,
                    Zeuge: response.zeugen,
                    Benutzer: response.user,
                    'Adresse - Mandant': response.globallySameAddress.mandant || [],
                    'Adresse - Schädiger|Zeugen': response.globallySameAddress.person || [],
                    'Adresse - Benutzer': response.globallySameAddress.userAdditionalContactData || [],
                    'Kennzeichen - Gegner': response.kennzeichenGegner || [],
                    'Kennzeichen - Mandant': response.kennzeichenMandant || []
                };

                $.each(iterationObj, function (name, data) {
                    data.forEach(function (dataset) {
                        kollisionsTBody.append(buildKollisionTR(name, dataset));
                    });
                });

                tippy(Array.from(document.querySelectorAll('.overlay-body [title]')), {
                    size: 'large',
                    arrow: true,
                    allowHTML: true,
                    content (reference) {
                        const title = reference.getAttribute('title');
                        reference.removeAttribute('title');
                        return title;
                    },
                    onCreate (instance) {
                        instance.reference.classList.add('tippy');
                    },
                });
            } else {
                submitButton.attr('disabled', true);
            }

            return;
        }

        submitButton.attr('disabled', false);
        kollisionsabfrageContainer.hide();
    });
};

/**
 * manipulateDossierHeading
 * @param amountOfDossiers
 * @param element
 * @param withAmount
 */
window.manipulateDossierHeading = function (amountOfDossiers, element, withAmount) {
    if (withAmount === undefined) {
        withAmount = true;
    }

    if (amountOfDossiers > 0) {
        element.removeClass('green').addClass('red');

        if (withAmount) {
            element.parent().find('[data-amount-hint]').text('(' + amountOfDossiers + ')').show();
        }

        return;
    }

    element.addClass('green').removeClass('red');
};

/**
 * handleDateInput
 * @param input
 */
window.handleDateInput = function (input) {
    input = $(input);
    var newDate = dateInput(input);
    if (typeof newDate != 'undefined') {
        input.val(newDate);
    }
};

/**
 * dateInput
 * @param item
 * @returns {string|*|[]|undefined}
 */
window.dateInput = function (item) {
    var date = item.val();
    if (date.indexOf('.') === -1) {
        if (date.length === 6) {
            date = [date.slice(0, 2), '.', date.slice(2)].join('');
            date = [date.slice(0, 5), '.', date.slice(5)].join('');
            date = [date.slice(0, 6), '20', date.slice(6)].join('');
        } else if (date.length === 8) {
            date = [date.slice(0, 2), '.', date.slice(2)].join('');
            date = [date.slice(0, 5), '.', date.slice(5)].join('');
        }
        return date;
    }
};

/**
 * validateEmail
 * source: https://html.form.guide/best-practices/validate-email-address-using-javascript/
 *
 * @param email
 * @returns {boolean}
 */
window.validateEmail = function (email) {
    var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    return re.test(email);
};

/**
 * foldboxCustom
 * @param obj
 */
window.foldboxCustom = function (obj) {
    var ele = $(obj);
    var payload = {};
    var state = undefined;

    var wrap = ele.closest('.foldbox-wrap');
    if (wrap.hasClass('open')) {
        wrap.removeClass('open').find('.foldbox-body').stop().slideUp(250);
        state = 'closed';
    } else {
        wrap.addClass('open').find('.foldbox-body').stop().slideDown(250);
        state = 'open';
    }

    if (state !== undefined) {
        payload[ele.attr('id')] = state;
        $.get('ajax/handling.php', payload);
    }
};

/**
 * loadTippy
 */
window.loadTippy = function (theme = 'dark', selector = '[title]') {
    switch (theme) {
        case 'dark':
            tippy(selector, {
                size: 'large',
                arrow: true,
                allowHTML: true,
                content (reference) {
                    const title = reference.getAttribute('title');
                    reference.removeAttribute('title');
                    return title;
                },
                onCreate (instance) {
                    instance.reference.classList.add('tippy');
                },
            });
            break;
        case 'light':
            tippy(selector, {
                theme: 'light',
                size: 'large',
                arrow: true,
                allowHTML: true,
                placement: 'bottom',
                content (reference) {
                    const title = reference.getAttribute('title');
                    reference.removeAttribute('title');
                    return title;
                },
                onCreate (instance) {
                    instance.reference.classList.add('tippy');
                },
            });
        break;
    }
};

/**
 * Datum in deutsches Format konvertieren
 *
 * @param unformattedDate
 * @returns {string}
 */
window.formatToDeDate = function (unformattedDate) {
    const datetimeObj = new Date(unformattedDate);
    const dateFromY = new Intl.DateTimeFormat('de', { year: 'numeric' }).format(datetimeObj);
    const dateFromM = new Intl.DateTimeFormat('de', { month: '2-digit' }).format(datetimeObj);
    const dateFromD = new Intl.DateTimeFormat('de', { day: '2-digit' }).format(datetimeObj);

    return `${dateFromD}.${dateFromM}.${dateFromY}`;
};

window.getDate = function (type = 'day') {
    var today = new Date();

    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    // get month with prefixed 0
    if (month < 10) {
        month = '0' + month;
    }

    // get day with prefixed 0
    if (day < 10) {
        day = '0' + day;
    }

    if (type === 'month') {
        today = year + '-' + month;
    } else {
        today = year + '-' + month + '-' + day;
    }
    console.log(today);
    return today;
};

// DOCUMENT-READY
$(document).ready(function () {
    jQuery.datetimepicker.setLocale('de');
    disableAutocomplete();

    $.each($('[data-copy]'), function (i, el) {

        if (el.title === '') {
            el.title = 'klicken um zu kopieren';
        } else if (el.title !== 'klicken um zu kopieren') {
            el.title = el.title + '<br>klicken um zu kopieren';
        }

        $(el).on('click', copyOnClick);
    });

    // User-Menu Touch-Geräte
    $('#user-panel').click(function () {
        $(this).toggleClass('on');
    });

    // Temp Pageswitch
    $('#temp-pageswitch').find('.tps-toggle').on('click', function () {
        $(this).parent('#temp-pageswitch').toggleClass('tps-open');
        return false;
    });

    $('.notification-bullet').each(function () {
        $(this).on('click', function () {
            $('.notification-list').not($(this)).removeClass('notif-open');
            $(this).next('.notification-list').toggleClass('notif-open');
            return false;
        });
    });

    // Submenü Toggle
    $('.has-sub').on('click', function () {
        $(this).toggleClass('open').next('ul');
    });

    $(document).on('click', function (e) {
        var notificationBullet = $('.notification-bullet');
        if (notificationBullet.next('.notification-list').hasClass('notif-open') && $('.overlay').length === 0) {
            if ($(e.target).closest('#notification-holder').length === 0) {
                notificationBullet.next('.notification-list').removeClass('notif-open');
            }
        }
    });

    // Dashboard Open - Close
    $.each($('.section-head.dashboard'), function (i, el) {
        $(el).on('click', function (e) {
            e.preventDefault();
            toggleDashboardExpansion(el);
        });
    });

    // Switch Content (Tabs)
    $('.switch-content').each(function () {
        var switchHolder = $(this);
        // Erstes Element automatisch immer aktiv setzen
        switchHolder.find('*[data-sec]:first-child').addClass('active');
        switchHolder.find('*[data-anchor]:first-child').addClass('active');

        $(this).find('li[data-anchor]').on('click', function () {
            var switchEle = $(this);
            var switchAnchor = switchEle.attr('data-anchor');

            // Gewähltes Element aktiv setzen
            switchHolder.find('*[data-sec="' + switchAnchor + '"]').each(function () {
                switchHolder.find('*[data-sec]').removeClass('active');
                switchHolder.find('li[data-anchor]').removeClass('active');

                $(this).addClass('active');
                switchEle.addClass('active');
            });

            // Falls floatingButtons auf der Seite, anpassen
            floatingButtons();
        });
    });

    $('.simple-complete-input').simpleCompleter();

    $('body').on('click', '.datepicker', function () {
        var _this = $(this);
        var mayHaveDatepicker = _this.attr('readonly') === 'readonly' ? false : true;

        if (mayHaveDatepicker) {
            _this.datetimepicker({
                format: 'd.m.Y',
                formatDate: 'd.m.Y',
                timepicker: false,
                lang: 'de'
            }).focus();
        }
    }).on('click', '.datetimepicker', function () {
        var _this = $(this);
        var mayHaveDatepicker = _this.attr('readonly') === 'readonly' ? false : true;

        if (mayHaveDatepicker) {
            _this.datetimepicker({
                format: 'd.m.Y H:i',
                formatTime: 'H:i',
                formatDate: 'd.m.Y',
                lang: 'de'
            }).focus();
        }
    })
        // events
              .on('submit', 'form', function () {
                  $(this).find('.clone_model').remove();
              }).on('keypress', function (event) {
        if ($(event.target).is('textarea')) {
            return;
        }
        if (event.keyCode === 10 || event.keyCode === 13) {
            event.preventDefault();
        }
    });

    $('input.datepicker, input.datetimepicker').attr('autocomplete', 'off');

    $('#search-bullet').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('#search').blur();
            $('body').removeClass('search-open');
        } else {
            $(this).addClass('active');
            $('#search').focus();
            $('body').addClass('search-open');
        }
    });

    floatingButtons();

    $('.foldbox-header').on('click', function () {

        var ele = $(this);
        var payload = {};
        var state = undefined;

        var wrap = ele.closest('.foldbox-wrap');
        if (wrap.hasClass('open')) {
            wrap.removeClass('open').find('.foldbox-body').stop().slideUp(250);
            state = 'closed';
        } else {
            wrap.addClass('open').find('.foldbox-body').stop().slideDown(250);
            state = 'open';
        }

        if (state !== undefined) {
            payload[this.id] = state;
            $.get('ajax/handling.php', payload);
        }
    });

    // Progress Kreise initialisieren
    $.each($('.percircle'), function (i, el) {
        $(el).percircle();
    });

    fixedSidebar();

    tippy('[title]', {
        size: 'large',
        arrow: true,
        allowHTML: true,
        content (reference) {
            const title = reference.getAttribute('title');
            reference.removeAttribute('title');
            return title;
        },
        onCreate (instance) {
            instance.reference.classList.add('tippy');
        },
    });

    $('.optional-trigger').on('change', function () {
        optionalTrigger(this);
    }).each(function () {
        optionalTrigger(this);
    });

    $('body').on('change', '.if-firma input', function () {
        toggleArea($(this).closest('.optional-container'), 'firma', $(this).is(':checked'));
    }).on('change', '.if-person input', function () {
        toggleArea($(this).closest('.optional-container'), 'person', $(this).val() !== 'firma');
    });

    $('.horizontal-scroll-wrapper > *').on('mouseenter', function (e) {
        var ele = $(this);
        var childrenwidth = 0;
        ele.children().each(function () {
            childrenwidth += $(this).outerWidth();
        });
        if (childrenwidth > ele.outerHeight()) {
            ele.bind('mousewheel', function (e) {
                var deltaY = e.deltaY;
                var pos = ele.find('*:first-child').position().left;
                var newScrollPos = pos - 100;
                if (deltaY >= 0) {
                    newScrollPos = pos + 100;
                }
                newScrollPos = newScrollPos * -1;

                ele.stop().animate({ scrollLeft: Math.round(newScrollPos) + 'px' }, 0);

                return false;
            });
        }
    });
    foldableFieldsets();

    // Tabs notification history
    const tabWrap = $('.notification-wrap');
    if (tabWrap.length > 0) {
        const tabHeaderItems = tabWrap.find('.notification-tabs').find('li');
        const tabContent = tabWrap.find('.notification-content');

        let contentAjaxItems = [];
        tabHeaderItems.each(function () {
            $(this).on('click', function () {
                const contentToActivate = $(this).data('content');
                const contentEleToActivate = tabContent.children('#' + contentToActivate);

                // Toggle contents and add active classes to tabs and contents
                if (contentEleToActivate.length > 0) {
                    $(this).addClass('on').siblings().removeClass('on');
                    contentEleToActivate.addClass('active').siblings().removeClass('active');
                }

                // Content got an ajaxItem?
                const contentAjaxModule = $(this).data('module');

                // If it's not registered yet, load it
                if (typeof contentAjaxModule !== 'undefined' && typeof contentAjaxItems[contentAjaxModule] === 'undefined') {
                    contentAjaxItems[contentAjaxModule] = new ajaxItem(contentAjaxModule, { showLoader: true });
                    contentAjaxItems[contentAjaxModule].loader();
                }

                // Push ident to url
                const contentModuleIdent = $(this).data('ident');
                window.history.pushState('', '', '?tab=' + contentModuleIdent);
            });
        });
    }

    // Simple accordion
    const accordionSimple = $('.accordion-simple');
    if (accordionSimple.length > 0) {
        accordionSimple.children('strong').on('click', function () {
            $(this).toggleClass('active').next('div').slideToggle(150);
        });
    }
});

// WINDOW LOAD
$(window).on('load', function () {
    $('body').addClass('winload');
});

// WINDOW RESIZE
$(window).resize(function () {
    dialogBodyHeight();
});

// WINDOW TOUCH
$(window).on('touchmove scroll resize', function () {
    floatingButtons();
    fixedSidebar();
});
