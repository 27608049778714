'use strict';

/**
 * convertDateToTimestamp
 * @param date
 * @returns {number}
 */
window.convertDateToTimestamp = function (date) {
    return Date.parse(date.split('.').reverse().join('.'));
};

/**
 * resetAdditionalFilters
 */
window.resetAdditionalFilters = function () {
    $.each($('select, input'), function (i, el) {
        switch (el.tagName) {
            case 'INPUT':
                el.value = '';

                if(el.checked) {
                    el.checked = false;
                }
                break;
            case 'SELECT':
                el.selectedIndex = 0;
        }
    });
};

/**
 * resetEvent
 * @param btn
 * @param jsVar
 */
window.resetEvent = function (btn, jsVar) {
    btn.disabled = true;

    $.each($('input, select'), function (el) {
        switch (el.tagName) {
            case 'INPUT':
                el.value = '';
                break;
            case 'SELECT':
                el.selectedIndex = 0;
                el.disabled = false;
                break;
        }
    });

    resetAdditionalFilters();

    jsVar.updater({ filter: '', sortby: '' });

    btn.disabled = false;
};

/**
 * toogleAdditionalFilter
 * @param btn
 */
window.toogleAdditionalFilter = function (btn) {
    btn.disabled = true;

    var styleFilterWrap = $('#additional-filter');
    var classListString = btn.classList.toString();

    switch (styleFilterWrap.style.display) {
        case 'none':
            styleFilterWrap.style.display = 'block';
            btn.classList = classListString.replace('green', 'red');
            btn.innerText = '- weitere Filter ausblenden';
            break;
        case 'block':
            styleFilterWrap.style.display = 'none';
            btn.classList = classListString.replace('red', 'green');
            btn.innerText = '+ weitere Filter einblenden';
            break;
    }

    // Extrafilter zurücksetzen
    if (styleFilterWrap.display === 'none') {
        resetAdditionalFilters();
    }

    btn.disabled = false;
};

/**
 * validateFilterTimespans
 */
window.validateFilterTimespans = function () {
    var timespans = ['created', 'edited', 'archived'];
    var returnedObj = {};

    timespans.forEach(function (targetPraefix) {
        var startEl = $('#add-filter-' + targetPraefix + '-start');
        var endEl = $('#add-filter-' + targetPraefix + '-end');

        if (startEl.length !== 0 && endEl.length !== 0) {
            var startVal = startEl.val();
            var endVal = endEl.val();

            if (startVal !== '' && endVal !== '') {
                // wandelt 21.12.2018 in 2018-12-21 um damit Date.parse() es nativ versteht
                var startTimestamp = convertDateToTimestamp(startVal);
                var endTimestamp = convertDateToTimestamp(endVal);

                returnedObj[targetPraefix + '-start'] = startVal;
                returnedObj[targetPraefix + '-end'] = endVal;

                var method = startTimestamp > endTimestamp ? 'addClass' : 'removeClass';

                if (typeof method !== undefined) {
                    startEl.closest('.input-wrap')[method]('err');
                }
            }
        }
    });

    return returnedObj;
};

/**
 * filterUpdater
 * @param jsVar
 */
window.filterUpdater = function (jsVar) {
    var filter = validateFilterTimespans();

    filter.edituser     = $('#add-filter-edituser').val();
    filter.type_uid     = $('#add-filter-type').val();
    filter.cruser       = $('#add-filter-cruser').val();
    filter.team_uid     = $('#add-filter-team').val();

    if($('#add-filter-closed-files').is(':checked')) {
        filter.closed_files = 1;
    }

    $.each($('[data-column]'), function (i, element) {
        var $element = $(element);

        var value  = $element.val();
        var column = $element.data('column');

        if (!filter[column] && value !== '') {
            filter[column] = value;
        }
    });

    jsVar.updater({ filter: filter });
};

/**
 * fieldsetToggle
 */
window.fieldsetToggle = function () {
    var $this = $(this);

    var fieldset = $this.closest('fieldset');
    fieldset.hasClass('closed') ? fieldset.removeClass('closed') : fieldset.addClass('closed');

    $this.closest('div.fieldset-content').toggle();
};

/**
 * addFreeTextSearchBtnListener
 */
window.addFreeTextSearchBtnListener = function () {

    $.each($('button[data-target*="input-"]'), function (i, btn) {
        var $btn = $(btn);

        $btn.prop('disabled', true);

        var target = document.getElementById(btn.dataset.target);
        var correspondingSelect = $('#' + this.dataset.target.replace('input-', ''));

        switch (target.style.display) {
            case '':
            case 'none':
                target.style.display = 'block';

                $btn.removeClass('green').addClass('red');
                $btn.text('-');

                correspondingSelect.prop('disabled', true);
                correspondingSelect.prop('selectedIndex', 0);
                break;
            case 'block':
                target.style.display = 'none';
                target.value = '';

                $btn.removeClass('red').addClass('green');
                $btn.text('+');

                correspondingSelect.prop('disabled', false);
                break;
        }

        $btn.prop('disabled', false);
    });
};
